import { arcInnerRadius, arcWidth, gradientColours } from "../config/labels";
import { titleCase } from "./formattingHelpers";
import html2canvas from "html2canvas";

export const reformatScore = (score: number): string => {
  // Insert a space before the final character to improve kerning
  const scoreString = String(score);
  return scoreString;
  // if (scoreString.length === 1) return scoreString;
  // return [scoreString.slice(0, -1), " ", scoreString.slice(-1)].join("");
};

const exportAsImage = async (
  element: HTMLElement | null,
  imageFileName: string,
  side: string
) => {
  if (element !== undefined && element !== null) {
    let offset = 0;
    if (side === "reverse") {
      offset = 360;
    }
    const canvas = await html2canvas(element, {
      backgroundColor: null,
      removeContainer: true,
      height: 380,
      y: offset,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  }
};

const downloadImage = (blob: string, fileName: string) => {
  let fakeLink = window.document.createElement("a");
  // @ts-ignore
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;

const describeArc = (
  x: number,
  y: number,
  radius: number,
  spread: number,
  startAngle: number,
  endAngle: number
): string => {
  const innerStart = polarToCartesian(x, y, radius, endAngle);
  const innerEnd = polarToCartesian(x, y, radius, startAngle);
  const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
  const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    "M",
    outerStart.x,
    outerStart.y,
    "A",
    radius + spread,
    radius + spread,
    0,
    largeArcFlag,
    0,
    outerEnd.x,
    outerEnd.y,
    "L",
    innerEnd.x,
    innerEnd.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    1,
    innerStart.x,
    innerStart.y,
    "L",
    outerStart.x,
    outerStart.y,
    "Z",
  ].join(" ");
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const scoreToArc = (score: number, x: number, y: number) =>
  describeArc(
    x - arcInnerRadius - arcWidth,
    y,
    arcInnerRadius,
    arcWidth,
    -90,
    score * 18 - 90
  );

export const scoreToColour = (score: number): keyof typeof gradientColours => {
  if (score < 7) return "orange";
  if (score < 8.5) return "bronze";
  if (score < 9.5) return "silver";
  return "gold";
};

const getReleaseYear = (
  releaseDate: string | null | undefined,
  releaseYear: string | number | undefined
) => {
  if (releaseYear) return releaseYear;
  if (releaseDate) return new Date(releaseDate).getFullYear();
  return null;
};

export const getLabelText = (
  game: string,
  details: ProductDetails
): string[] => {
  const releaseYear = getReleaseYear(details.releaseDate, details.releaseYear);
  const contentsArray = [details.rarity];
  if (details.finish && !details.rarity?.includes(details.finish)) {
    contentsArray.push(titleCase(details.finish));
  }
  if (details.cardNumber) {
    contentsArray.push(String(details.cardNumber));
  }
  if (!releaseYear) {
    return [
      `${game} - ${details.setName}`,
      contentsArray.filter(Boolean).join(" "),
    ];
  }
  // if (details.setName.includes(String(releaseYear))) {
  //   return [
  //     `${game} - ${details.setName}`,
  //     [titleCase(details.rarity), titleCase(details.finish), details.cardNumber]
  //       .filter(Boolean)
  //       .join(" "),
  //   ];
  // }
  return [
    `${game} - ${details.setName}`,
    [releaseYear, ...contentsArray].filter(Boolean).join(" "),
  ];
};

export const getFinishAndRarity = (details: ProductDetails) => {
  const contentsArray = [];
  const rarity = /^[a-z]+$/.test(details.rarity || "")
    ? titleCase(details.rarity)
    : details.rarity;
  if (
    details.finish &&
    details.finish !== "Normal" &&
    !details.rarity?.includes(details.finish)
  ) {
    contentsArray.push(titleCase(details.finish));
  }
  contentsArray.push(rarity);
  return contentsArray.filter(Boolean).join(" ");
};

export const formatCardNumber = (cardNumber: string | number | undefined) => {
  if (!cardNumber) return undefined;
  if (/^\d+$/.test(String(cardNumber))) {
    return `#${cardNumber}`;
  }
  return cardNumber;
};

export const getFrontLine2Text = (details: ProductDetails) => {
  let setName = details.setName;
  if (details.cardNumber && details.cardNumber.toString().includes("TG")) {
    setName = setName.concat(" Trainer Gallery");
  }
  if (details.cardNumber && details.cardNumber.toString().includes("GG")) {
    setName = setName.concat(" Galarian Gallery");
  }
  return `${setName} | ${getFinishAndRarity(details)}`;
};

export const getBackLine3Text = (details: ProductDetails) => {
  let setName = details.setName;
  if (details.cardNumber && details.cardNumber.toString().includes("TG")) {
    setName = setName.concat(" Trainer Gallery");
  }
  if (details.cardNumber && details.cardNumber.toString().includes("GG")) {
    setName = setName.concat(" Galarian Gallery");
  }
  return `${setName}`;
};
export const getBackLine5Text = (details: ProductDetails) => {
  const releaseYear = getReleaseYear(details.releaseDate, details.releaseYear);
  const language = details?.language || "English";
  const cardNumber = formatCardNumber(details.cardNumber);
  return [releaseYear, language, cardNumber].filter(Boolean).join(" ");
};
